/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {getJwtDataFromToken} from '../../../Utils/getTokenDecoded'
import {AppToast} from '../../../Utils/AppToast'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {toast} = AppToast
  const {
    saveAuth,
    setCurrentUser,
    changeUserRole,
    changeUserId,
    changeDepartmentId,
    changeLocationId,
    changeUserEmail,
  } = useAuth()

  const setNotLoggedInStatus = (callbackFn: () => void, msg: string = 'Incorrect Credentials') => {
    setCurrentUser(undefined)
    changeUserRole(null)
    changeUserId('')
    changeLocationId('')
    changeDepartmentId('')
    changeUserEmail('')
    saveAuth(undefined)
    callbackFn()
    toast.error(msg)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)

        if (auth.success) {
          setCurrentUser(auth.token)
          const jwtData = getJwtDataFromToken(auth.token)
          changeUserRole(jwtData?.role)
          changeUserId(jwtData?.userId)
          changeLocationId(jwtData?.locationId || '')
          changeDepartmentId(jwtData?.departmentId || '')
          changeUserEmail(jwtData?.email || '')
          toast.success(auth?.message || 'Logged in successfully')
        } else {
          const message = auth.message
          setNotLoggedInStatus(() => {
            setStatus('The login details are incorrect')
            setSubmitting(false)
          }, message)
        }
      } catch (error) {
        console.error('login error', {error})
        setNotLoggedInStatus(() => {
          setStatus('The login details are incorrect')
          setSubmitting(false)
        })
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className='w-100 p-4'>
      <form
        className='form w-100 mx-auto'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/*

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <input
            placeholder='Please Enter User Id'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <input
            type='password'
            placeholder='Please Enter Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
          {/*TODO: when forgot password link is available change className here justify-content-between*/}
          <div
            className='d-flex mb-10 justify-content-end align-items-center'
            style={{
              flexGrow: 1,
            }}
          >
            {/* <Link to='/auth/forgot-password' className='link-primary text-gray-500'>
              Forgot Password ?
            </Link> */}
            {/* begin::Action */}
            <div className='d-grid'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </div>
          {/* begin::Link */}

          {/* end::Link */}
        </div>
        {/* <div className='text-gray-500 fw-semibold fs-6'>
          Don't have an account yet?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Sign up
          </Link>
        </div> */}
        <div className='text-center mb-11'></div>
        {/* end::Wrapper */}
      </form>
    </div>
  )
}
