import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import {forwardRef} from 'react'
import '../styles/reactDatePicker.styles.css'
interface IDatePickerProps {
  onChange: (date: Date) => void
  date: Date | null
  placeholder?: string
  className?: string
  disabled?: boolean
  label?: string
  isSelectedDates: boolean
  enabledDates?: Date[]
  isLoading?: boolean
}
const AppDatePicker = ({
  date,
  isSelectedDates = false,
  onChange,
  className = `btn-lg`,
  disabled = false,
  enabledDates = [],
  label = '',
  placeholder = 'Pick a date',
  isLoading = false,
}: IDatePickerProps) => {
  const handleDate = (date: Date | null) => {
    if (!date) return
    onChange(date)
  }

  const CustomInput = forwardRef((obj: any, ref: any) => {
    const value = obj?.value
    const onClick = obj?.onClick
    const btnText = isLoading ? placeholder : value || placeholder
    return (
      <button className={`btn btn-primary ${className}`} onClick={onClick} ref={ref}>
        {btnText}
      </button>
    )
  })
  return isSelectedDates && enabledDates?.length > 0 ? (
    <DatePicker
      selected={date}
      onChange={handleDate}
      includeDates={enabledDates}
      showDateSelect
      placeholderText={placeholder}
      customInput={<CustomInput />}
      disabled={disabled}
      popperClassName='z-2000'
    />
  ) : (
    <DatePicker
      selected={date}
      onChange={handleDate}
      showDateSelect
      placeholderText={placeholder}
      customInput={<CustomInput />}
      disabled={disabled}
      popperClassName='z-2000'
    />
  )
}

export default AppDatePicker
