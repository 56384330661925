import React, {useState} from 'react'
import SurveyLocationStockyard from '../../MaterialDashboard/SurveyLocationStockyard'
import AppDatePicker from '../../../components/AppDatePicker'
import AppSelect, {OptionObjInterface} from '../../../components/AppSelect'
import {useLocationStockyard} from '../../../hooks/useLocationStockyard'
import {useDatePicker} from '../../../hooks/useDatePicker'
import {useModal} from '../../../hooks/useModal'
import AppModal from '../../../components/AppModal/AppModal'
import {AppToast} from '../../../Utils/AppToast'

const stockyardParameters: OptionObjInterface[] = [
  {label: 'Slope Stability', value: 'slopeStability'},
  {label: 'Boundary and Buffer Zones', value: 'boundaryBufferZone'},
  {label: 'Material Segregation', value: 'materialSegregation'},
  {label: 'Fuel consumption', value: 'fuelConsumption'},
  {label: 'Cycle times', value: 'cycleTimes'},
  {label: 'Consumption rates', value: 'consumptionRates'},
  {label: 'Temperature Monitoring (coal/coke)', value: 'temperatureMonitoring'},
]

const ShowStockyardAnalysis = () => {
  const {toast} = AppToast
  const {closeModal, isModalVisible, openModal} = useModal()

  const handleOpenModal = () => {
    openModal()
  }
  const handleCloseModal = () => {
    closeModal()
  }
  const [displayLicenseText, setDisplayLicenseText] = useState(false)
  const [selectedStockyardParameter, setSelectedStockyardParameter] = useState('')
  const {locationId, stockyardId, onStockyardChange, onLocationChange} = useLocationStockyard()
  const handleStockyardChange = (stockyardId: string) => {}
  const {selectedDate, changeSelectedDate} = useDatePicker()
  const handleDateFilterChange = (date: Date) => {
    changeSelectedDate(date)
  }
  const handleStockyardParameterChange = (stockyardParameter: string) => {
    if (!selectedDate) {
      toast.warn('Select date')
      return
    }
    if (!stockyardId) {
      toast.warn('Select stockyard')
      return
    }
    setSelectedStockyardParameter(stockyardParameter)
    openModal()
  }
  return (
    <div className='p-4 bg-light rounded shadowm-lg'>
      <p className='text-center h3'>Stockyard Analysis</p>
      <SurveyLocationStockyard
        locationValue={locationId}
        onLocationChange={(locationId: string) => onLocationChange(locationId)}
        onStockyardChange={(stockyardId: string) =>
          onStockyardChange(stockyardId, handleStockyardChange)
        }
        stockyardValue={stockyardId}
      />
      <div className='d-flex justify-content-center align-items-center flex-row'>
        <div className='mx-5'>
          <AppDatePicker
            date={selectedDate}
            onChange={(date: Date) => handleDateFilterChange(date)}
            isSelectedDates={true}
            placeholder='Select Date'
          />
        </div>
        <div className='mx-5'>
          <AppSelect
            data={stockyardParameters}
            value={selectedStockyardParameter}
            onChange={handleStockyardParameterChange}
            placeholder='Select Stockyard Parameter'
          />
        </div>
      </div>
      {isModalVisible ? (
        <AppModal
          headerText='Stockyard Analysis'
          onClose={handleCloseModal}
          visible={isModalVisible}
        >
          {/* <div className='d-flex justify-content-center'></div> */}
          <p className='p-4 text-center h4 mx-auto'>You have no license</p>
        </AppModal>
      ) : null}
    </div>
  )
}

export default ShowStockyardAnalysis
