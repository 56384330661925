import {FC} from 'react'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isParams?: boolean
  params?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isParams = false,
  params = '',
}) => {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  const className = clsx('menu-link without-sub', {active: isActive})

  const handleClick = () => {
    if (!params) return
    navigate(`${to}/${params}`)
  }

  const ChildComponent = (
    <>
      {hasBullet && (
        <span className='menu-bullet'>
          <span className='bullet bullet-dot'></span>
        </span>
      )}
      {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
        <span className='menu-icon'>
          {' '}
          {/* <KTSVG path={icon} className='svg-icon-2' /> */}
          <i className={clsx('bi fs-3', icon)}></i>
        </span>
      )}
      {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
        <i className={clsx('bi fs-3', fontIcon)}></i>
      )}
      <span className='menu-title' style={{fontSize: '1.1rem'}}>
        {title}
      </span>
    </>
  )

  return (
    <div className='menu-item'>
      {isParams ? (
        <div className={className} onClick={handleClick}>
          {ChildComponent}
        </div>
      ) : (
        <Link className={className} to={to}>
          {ChildComponent}
        </Link>
      )}

      {children}
    </div>
  )
}

export {SidebarMenuItem}
