import {differenceInCalendarDays, isWithinInterval, startOfDay} from 'date-fns'
import {DateRangePicker, Range, RangeKeyDict} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

type Props = {
  isVisible: boolean
  onChange: (dateRange: RangeKeyDict) => void
  dateState: Range[]
  highlightedDates?: Date[]
}

export default function AppDateRangePicker({
  isVisible,
  onChange,
  dateState,
  highlightedDates = [],
}: Props) {
  const handleChange = (dateRange: RangeKeyDict) => {
    onChange(dateRange)
  }

  const isHighlighted = (date: Date) => {
    return highlightedDates.some(
      (highlightDate) => differenceInCalendarDays(date, highlightDate) === 0
    )
  }

  const isInRange = (date: Date) => {
    return dateState.some((range) => {
      if (range.startDate && range.endDate) {
        return isWithinInterval(date, {
          start: startOfDay(range.startDate),
          end: startOfDay(range.endDate),
        })
      }
      return false
    })
  }

  const dayContentRenderer = (date: Date) => {
    let textColor = 'blue'

    if (isHighlighted(date)) {
      textColor = 'red'
    }

    if (isInRange(date)) {
      textColor = '#fff' // Adjust the color so it's readable against the range background
    }

    const style: React.CSSProperties = {
      width: '100%',
      height: '100%',
      display: 'inline-block',
      lineHeight: '27px', // Align the text vertically
      textAlign: 'center',
      color: textColor,
      borderRadius: '50%',
    }

    return <div style={style}>{date.getDate()}</div>
  }

  return isVisible ? (
    <DateRangePicker
      editableDateInputs={true}
      onChange={(item) => handleChange(item)}
      moveRangeOnFirstSelection={false}
      retainEndDateOnFirstSelection={true}
      ranges={dateState}
      dayContentRenderer={highlightedDates?.length === 0 ? undefined : dayContentRenderer}
    />
  ) : null
}
