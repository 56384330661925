import {FilterSettingsModel, FilterType, PageSettingsModel} from '@syncfusion/ej2-react-grids'
import {jwtDecode} from 'jwt-decode'
import {IGetSurveyData, RadioButtonOption} from '../modules/MaterialDashboard/model'
import {AppToast} from './AppToast'
import {
  IVolumetricAnalysisList,
  snapsObj,
} from '../modules/MaterialDashboard/PileManagement/Models/volumetricAnalysisModel'
import {
  IPileCountDataFromServer,
  IPileCountList,
} from '../modules/MaterialDashboard/PileManagement/Models/pileApprovalRejectionModel'
import moment from 'moment'
import {IFileData} from '../modules/MaterialDashboard/PileManagement/Models/apiModel'
import {fileTypeObj, fileUplaodStatus} from './constants'

const BASE_URL = process.env.REACT_APP_SERVER_URL

export const dateFormat = 'YYYY-MM-DD'
export const appDefaultFormat = 'MMM D, YYYY'
export const ddmmyyyyformat = 'DD-MM-YYYY'
export const getDateInDefaultFormat = (date: Date, format = dateFormat) =>
  moment(date).format(format)

export const getJwtDataFromToken = (token: string) => {
  const decoded: Record<string, any> = jwtDecode(token)
  return decoded
}

export const getISOString = (date: Date) => {
  const formattedDateISO = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  )?.toISOString()
  return formattedDateISO
}

export function getListWithSerialNo<dataType>(data: dataType[]) {
  return data.map((ele, index) => ({...ele, srno: index + 1}))
}

export function getGridOptionsForDataTable(pageSize: number = 10, type: FilterType = 'Menu') {
  const pageSettings: PageSettingsModel = {pageSize, pageSizes: true}
  const filterSettings: FilterSettingsModel = {type}

  const gridOptions = {
    pageSettings,
    filterSettings,
  }
  return gridOptions
}
export function getYesOrNoOptions() {
  const yesNoOptions: RadioButtonOption[] = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]
  return yesNoOptions
}

export function getUnsuccessfulToastMessage(error: any, defaultMessage: string) {
  const {toast} = AppToast
  if (error?.response && error?.response?.data && error?.response?.data?.message) {
    const errorMessage = error?.response?.data?.message
    toast.error(errorMessage)
  } else {
    console.log('in default part of toast')
    toast.error(defaultMessage)
  }
}

export const checkIsSameDate = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.getDate() === secondDate.getDate() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  )
}

export const formatPileCountList = (pileCountData: IPileCountDataFromServer[]) => {
  const formattedList: IPileCountList[] = pileCountData?.map((pileCount) => {
    const {completed, id, name, pending, total} = pileCount
    return {completed, id, name, pending, total}
  })
  return formattedList
}

const getArePhotosAlreadyUploaded = (files: IFileData[]) => {
  const initialSnapsObj = {
    demSnap: false,
    orthoSnap: false,
    picSnap: false,
    volumetricComp: false,
    pointCloud: false,
    pointCloudLas: false,
    demTiff: false,
    orthoTiff: false,
  }
  if (files?.length === 0) return initialSnapsObj
  const snapsObj = {...initialSnapsObj}
  const totalFiles = files?.length
  for (let i = 0; i < totalFiles; i++) {
    const currentFile = files[i]
    const fileType = currentFile.file_type
    if ([fileTypeObj.Snap, fileTypeObj.DemSnap].includes(fileType)) snapsObj.demSnap = true
    if ([fileTypeObj.Snap, fileTypeObj.OrthoSnap].includes(fileType)) snapsObj.orthoSnap = true
    if ([fileTypeObj.Snap, fileTypeObj.PicSnap].includes(fileType)) snapsObj.picSnap = true
    if ([fileTypeObj.Images].includes(fileType)) snapsObj.volumetricComp = true
    if ([fileTypeObj.Pointcloud].includes(fileType)) {
      snapsObj.pointCloud = true
      snapsObj.pointCloudLas = true
    }
    if ([fileTypeObj.Dem].includes(fileType)) snapsObj.demTiff = true
    if ([fileTypeObj.Ortho].includes(fileType)) snapsObj.orthoTiff = true
  }
  return snapsObj
}

export const getOutdoorStockyardFileStatus = (data: IGetSurveyData[]) => {
  const initialOutdoorStockyardFileStatusObj = {
    [snapsObj.demTiff]: false,
    [snapsObj.orthoTiff]: false,
    [snapsObj.pointCloudLas]: false,
  }
  if (data?.length === 0) return initialOutdoorStockyardFileStatusObj
  const outdoorsnapsObj = {...initialOutdoorStockyardFileStatusObj}
  const lastSurvey = data[data.length - 1]
  const files = lastSurvey?.Files
  const totalFiles = files?.length
  if (totalFiles === 0) return initialOutdoorStockyardFileStatusObj
  for (let i = 0; i < totalFiles; i++) {
    const currentFile = files[i]
    const fileType = currentFile.file_type
    if ([fileTypeObj.Pointcloud].includes(fileType)) {
      outdoorsnapsObj[snapsObj.pointCloudLas] = true
    }
    if ([fileTypeObj.Dem].includes(fileType)) outdoorsnapsObj[snapsObj.demTiff] = true
    if ([fileTypeObj.Ortho].includes(fileType)) outdoorsnapsObj[snapsObj.orthoTiff] = true
  }
  return outdoorsnapsObj
}

export const formatVolumetricAnalysisList = (data: IGetSurveyData[]) => {
  const volumetricAnalysisList: IVolumetricAnalysisList[] = data.map((survey) => ({
    id: survey.id,
    pileCode: survey.pileCode,
    pileName: survey.name,
    locationName: survey.Department?.location?.name,
    stockyard: survey.Stockyard?.name,
    derivedErpQuantity: survey.derivedErpQuantity,
    volume: Number(survey?.volume),
    additionalInformation: survey?.additionalInformation || '',
    toeConfidence: survey?.toeConfidence || 'Low',
    surfaceConfidence: survey?.toeConfidence || 'Low',
    date: new Date(survey.date),
    bulkMaterialName: `${survey?.BulkMaterial?.name}-${survey?.BulkMaterial?.code}`,
    health: [],
    combinedPiles: survey?.combinedPiles || false,
    obstructionStructure: survey?.obstructionStructure || false,
    pileHealth: survey?.pileHealth || false,
    standingWater: survey?.pileHealth || false,
    tarpaulinOnPile: survey?.tarpaulinOnPile || false,
    vegetationGrowth: survey?.vegetationGrowth || false,
    ...getArePhotosAlreadyUploaded(survey?.Files),
  }))
  return volumetricAnalysisList
}

export const checkIsFileStatusOk = (status: string) => {
  const {DeletedFromServerAfterProcessing, UploadedToCloudAfterProcessing} = fileUplaodStatus
  return [DeletedFromServerAfterProcessing, UploadedToCloudAfterProcessing].includes(status)
}

export function renameFile(file: File, newName: string) {
  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  })
}

export const navigateToViewPointCloudRoute = (surveyId: string, files: IFileData[]) => {
  const lastFile = files
    .reverse()
    .find(
      (file) =>
        file.file_type === fileTypeObj.Pointcloud &&
        [
          fileUplaodStatus.DeletedFromServerAfterProcessing,
          fileUplaodStatus.UploadedToCloudAfterProcessing,
        ].includes(file.status)
    )
  if (!lastFile) return
  const fileId = lastFile?.id
  const path = `${BASE_URL}/file-uploads/potree/${fileId}`
  const source = path
  window.open(source, '_blank', 'rel=noopener noreferrer')
}

export const getClientLocalStorageKey = (pathName: string) => `${pathName}-client`
export const getLocationLocalStorageKey = (pathName: string) => `${pathName}-location`
export const getstockyardLocalStorageKey = (pathName: string) => `${pathName}-stockyard`
export const getdepartmentLocalStorageKey = (pathName: string) => `${pathName}-department`
export const getStockyardListLocalStorageKey = (pathName: string) => `${pathName}-stockyardList`
export const getSurveyDateLocalStorageKey = (pathName: string) => `${pathName}-surveyDate`
