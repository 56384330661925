import {useAuth, userRoleObj} from '../modules/auth'

export const useRole = () => {
  const {userRole} = useAuth()
  const {
    Auditor,
    CompanyAdmin,
    DatesmetronAnalyst,
    LocationHead,
    Superadmin,
    Supervisor,
    Surveyor,
    User,
  } = userRoleObj
  const isAuditor = userRole == Auditor
  const isCompanyAdmin = userRole === CompanyAdmin
  const isLocationHead = userRole === LocationHead
  const isDepartmentUser = userRole === User
  const isSuperadmin = userRole === Superadmin
  const isAnalyst = userRole === DatesmetronAnalyst
  const isSupervisor = userRole === Supervisor
  const isSurveyor = userRole === Surveyor
  const isDatesmetronUser = [isSuperadmin, isAnalyst, isSupervisor, isSurveyor].some(
    (value) => !!value
  )
  const isCompanyUser = [isAuditor, isCompanyAdmin, isLocationHead, isDepartmentUser].some(
    (value) => !!value
  )
  return {
    isAuditor,
    isAnalyst,
    isCompanyAdmin,
    isLocationHead,
    isDepartmentUser,
    isSuperadmin,
    isSupervisor,
    isSurveyor,
    isDatesmetronUser,
    isCompanyUser,
  }
}
