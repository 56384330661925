import React from 'react'
import {useModal} from '../../../hooks/useModal'
import AppButton from '../../../components/AppButton'
import AppSubmitButtonContainer from '../../../components/AppSubmitButtonContainer'
import AppModal from '../../../components/AppModal/AppModal'

type Props = {}

const ShowRealTimeMeasurements = (props: Props) => {
  const {closeModal, isModalVisible, openModal} = useModal()

  const handleOpenModal = () => {
    openModal()
  }
  const handleCloseModal = () => {
    closeModal()
  }

  return (
    <div className='p-4 bg-light my-10'>
      <p className='text-center h3'>Real Time Measurements</p>
      <AppSubmitButtonContainer>
        <AppButton label={'Measure'} onClick={handleOpenModal} />
      </AppSubmitButtonContainer>
      {isModalVisible ? (
        <AppModal
          headerText='Real Time Measurements'
          onClose={handleCloseModal}
          visible={isModalVisible}
        >
          {/* <div className='d-flex justify-content-center'></div> */}
          <p className='p-4 text-center h4 mx-auto'>You have not subscribed</p>
        </AppModal>
      ) : null}
    </div>
  )
}

export default ShowRealTimeMeasurements
