import axios from 'axios'
import {IAddStockyard} from './Stockyard/AddStockyard/model'
import {
  IDeActivateResponse,
  IUpdateDepartment,
  IUpdateLocation,
  IUsersListFromServer,
} from './model'

const BASE_URL = process.env.REACT_APP_SERVER_URL
const GET_ALL_LOCATION_URL = `${BASE_URL}/location-departments/get-all-locations`
const GET_LOCATION_BY_ID = `${BASE_URL}/location-departments/get-location`
const GET_DEPARTMENT_BY_LOCATION_ID_URL = `${BASE_URL}/location-departments/get-all-department`
const GET_DEPARTMENT_BY_DEPARTMENT_ID_URL = `${BASE_URL}/location-departments/get-department`
const GET_ALL_DEPARTMENTS = `${BASE_URL}/location-departments/get-all-departments`
const GET_ALL_STOCKYARDS = `${BASE_URL}/stockyard/get-all-stockyards`
const DELETE_STOCKYARD_BY_ID = `${BASE_URL}/stockyard/delete-stockyard`
const UPDATE_STOCKYARD_BY_ID_URL = `${BASE_URL}/stockyard/update-stockyard`
const GET_ALL_USERS_URL = `${BASE_URL}/auth/get-all-users`
const ACTIVATE_USER_URL = `${BASE_URL}/auth/activate-user`
const DEACTIVATE_USER_URL = `${BASE_URL}/auth/deactivate-user`
const EDIT_LOCATION_URL = `${BASE_URL}/location-departments/update-location`
const EDIT_DEPARTMENT_URL = `${BASE_URL}/location-departments/update-department`

export function getAllLocations<locationType>() {
  return axios.get<locationType[]>(GET_ALL_LOCATION_URL)
}
export function getLocationById<locationType>(locationId: string) {
  return axios.get<locationType>(`${GET_LOCATION_BY_ID}/${locationId}`)
}
export function getDepartmentByLocationId<DepartmentType>(locationId: string) {
  return axios.get<DepartmentType[]>(`${GET_DEPARTMENT_BY_LOCATION_ID_URL}/${locationId}`)
}
export function getDepartmentByDepartmentId<DepartmentType>(departmentId: string) {
  return axios.get<DepartmentType>(`${GET_DEPARTMENT_BY_DEPARTMENT_ID_URL}/${departmentId}`)
}
export function getAllDepartments<DepartmentType>() {
  return axios.get<DepartmentType[]>(GET_ALL_DEPARTMENTS)
}
export function getAllStockyards<StockyardType>() {
  return axios.get<StockyardType[]>(GET_ALL_STOCKYARDS)
}
export function deleteStockyardById(id: string) {
  return axios.delete(`${DELETE_STOCKYARD_BY_ID}/${id}`)
}
export const updateStockyardById = (id: string, stockyardDetails: IAddStockyard) => {
  return axios.patch(`${UPDATE_STOCKYARD_BY_ID_URL}/${id}`, stockyardDetails)
}
export const getAllUsers = () => {
  return axios.get<IUsersListFromServer[]>(GET_ALL_USERS_URL)
}
export const activateUser = (userId: string) => {
  return axios.patch(`${ACTIVATE_USER_URL}/${userId}`)
}
export const deActivateUser = (userId: string) => {
  return axios.patch<IDeActivateResponse>(`${DEACTIVATE_USER_URL}/${userId}`)
}
export const updateLocationByLocationId = (locationId: string, data: IUpdateLocation) => {
  return axios.patch(`${EDIT_LOCATION_URL}/${locationId}`, data)
}
export const updateDepartmentByDepartmentId = (departmentId: string, data: IUpdateDepartment) => {
  return axios.patch(`${EDIT_DEPARTMENT_URL}/${departmentId}`, data)
}
