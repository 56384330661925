import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  Filter,
  Resize,
  Toolbar,
  PdfExport,
  ExcelExport,
  Grid,
  Search,
  DetailRow,
  Edit,
  ToolbarItems,
  EditType,
  IEditCell,
  PdfExportProperties,
  PageOrientation,
} from '@syncfusion/ej2-react-grids'
import {GridModel, EditSettingsModel, EditMode} from '@syncfusion/ej2-grids'
import {useMemo} from 'react'
const toolbarOptions: Record<string, ToolbarItems> = {
  search: 'Search',
  pdf: 'PdfExport',
  excel: 'ExcelExport',
  edit: 'Update',
}

export interface Column {
  field: string
  headerText: string
  width?: string
  textAlign?: string
  format?: string
  template?: (props: any) => JSX.Element
  isPrimaryKey?: boolean
  editType?: EditType
  edit?: IEditCell
  allowSearching?: boolean
  allowEditing?: boolean
  editTempate?: any
}

interface childDataInterface<T> {
  parentDetails: any
  columns: Column[]
  dataSource: T[]
  queryString: string
  load: () => void
}

interface AppDataTableProps<T> {
  data: T[]
  columns: Column[]
  gridOptions?: GridModel
  childData?: childDataInterface<T> | undefined
  allowPaging?: boolean
  ActionColumn?: (props: any) => JSX.Element
  isActionColumn?: boolean
  pdfFileName?: string
  excelFileName?: string
  allowSorting?: boolean
  allowFiltering?: boolean
  allowResizing?: boolean
  allowSearching?: boolean
  allowEditing?: boolean
  allowToolbar?: boolean
  allowPdfExport?: boolean
  allowExcelExport?: boolean
  allowChildData?: boolean
  editMode?: EditMode
  updateData?: (data: any) => void
  defaultActionColumnWidth?: string
  editType?: EditType
  edit?: IEditCell
  pageOrientation?: PageOrientation
}

const AppDataTable = <T,>({
  data,
  columns,
  gridOptions,
  childData = undefined,
  ActionColumn,
  isActionColumn = false,
  pdfFileName = '',
  excelFileName = '',
  allowPaging = true,
  allowSorting = true,
  allowFiltering = true,
  allowResizing = true,
  allowExcelExport = false,
  allowPdfExport = false,
  allowSearching = true,
  allowEditing = false,
  allowToolbar = true,
  allowChildData = false,
  editMode = 'Normal',
  updateData = (data: any) => {},
  defaultActionColumnWidth = '200',
  editType = 'defaultEdit',
  edit = {},
  pageOrientation = 'Landscape',
}: AppDataTableProps<T>) => {
  const servicesArray = [
    {isAllowed: allowPaging, service: Page},
    {isAllowed: allowSorting, service: Sort},
    {isAllowed: allowFiltering, service: Filter},
    {isAllowed: allowResizing, service: Resize},
    {isAllowed: allowToolbar, service: Toolbar},
    {isAllowed: allowPdfExport, service: PdfExport},
    {isAllowed: allowExcelExport, service: ExcelExport},
    {isAllowed: allowSearching, service: Search},
    {isAllowed: allowChildData, service: DetailRow},
    {isAllowed: allowEditing, service: Edit},
  ]

  const toolbarItemsArray: {isAllowed: boolean; toolbarItem: ToolbarItems}[] = [
    {isAllowed: allowEditing, toolbarItem: toolbarOptions.edit},
    {isAllowed: allowPdfExport, toolbarItem: toolbarOptions.pdf},
    {isAllowed: allowExcelExport, toolbarItem: toolbarOptions.excel},
    {isAllowed: allowSearching, toolbarItem: toolbarOptions.search},
  ]

  const toolbarItemsBtns: ToolbarItems[] = toolbarItemsArray
    .filter(({isAllowed}) => isAllowed)
    .map(({toolbarItem}) => toolbarItem)

  const filteredServiceArray = useMemo(
    () => servicesArray.filter(({isAllowed}) => isAllowed).map(({service}) => service),
    [servicesArray]
  )

  const searchSettings = {
    fields: columns.map(({field}) => field),
    ignoreCase: true,
    operator: 'contains',
  }

  let grid: Grid | null

  const editOptions: EditSettingsModel = {allowEditing, mode: editMode}

  const handleToolbarClick = (args: any) => {
    if (!grid) return
    if (args?.item?.id?.includes('pdfexport')) {
      grid?.pdfExport({
        fileName: pdfFileName,
        hierarchyExportMode: 'All',
        pageOrientation: pageOrientation,
      })
    }
    if (args?.item?.id?.includes('excelexport')) {
      grid?.excelExport({
        fileName: excelFileName,
        hierarchyExportMode: 'All',
        // dataSource: data,
      })
    }
  }
  const handleEditChange = (state: any) => {
    if (state?.action !== 'edit') {
      return
    }
    updateData(state?.data)
  }
  return (
    <GridComponent
      dataSource={data}
      allowPaging={allowPaging}
      allowSorting={allowSorting}
      allowFiltering={allowFiltering}
      allowResizing={allowResizing}
      allowTextWrap={true}
      childGrid={childData}
      toolbar={toolbarItemsBtns}
      allowPdfExport={allowPdfExport}
      allowExcelExport={allowExcelExport}
      toolbarClick={handleToolbarClick}
      editSettings={editOptions}
      // searchSettings={searchSettings}
      ref={(g) => (grid = g)}
      actionComplete={handleEditChange}
      {...gridOptions}
    >
      <ColumnsDirective>
        {columns.map((col, index) => (
          <ColumnDirective
            key={index}
            allowSearching={allowSearching}
            allowEditing={allowEditing}
            {...col}
          />
        ))}
        {isActionColumn ? (
          <ColumnDirective
            headerText='Action'
            template={ActionColumn}
            allowSearching={true}
            width={defaultActionColumnWidth}
          />
        ) : null}
      </ColumnsDirective>
      <Inject services={filteredServiceArray} />
    </GridComponent>
  )
}

export default AppDataTable
