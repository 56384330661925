import {dateFormat, getDateInDefaultFormat} from '../../../../Utils/getTokenDecoded'
import {fileUploadForOutdoorStockyardRequest} from '../Models/apiModel'
import {uploadFileToGCPForOutdoorStockyard} from '../service'

export const getDateForFilesUrl = (date: Date) => {
  return getDateInDefaultFormat(date, dateFormat)
}

export const getFileUploadUrlForOutdoorStockyard = async (
  request: fileUploadForOutdoorStockyardRequest
) => {
  try {
    const {data} = await uploadFileToGCPForOutdoorStockyard(request)
    const {publicUrl} = data
    if (!publicUrl || publicUrl?.trim() === '') return null
    return publicUrl
  } catch (error) {
    console.error('get file upload url for outdoor stockyard error', error)
    return null
  }
}
