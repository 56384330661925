import {useState} from 'react'
import {ISurveyDataAndCount} from '../modules/MaterialDashboard/model'

export const useDatePicker = (initialDate: Date | null = null) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(() => initialDate)
  const [surveyDataAndCount, setSurveyDateAndCount] = useState<ISurveyDataAndCount[]>([])
  const [dateLoading, setDateLoading] = useState(false)

  const changeDateLoading = (status: boolean) => setDateLoading(status)

  const changeSelectedDate = (
    date: Date | null,
    callbackFn: (date: Date) => void = (date: Date) => {}
  ) => {
    setSelectedDate(date)
    if (!date) return
    callbackFn(date)
  }

  const changeSurveyDateAndCount = (surveyDateAndCountArray: ISurveyDataAndCount[]) => {
    setSurveyDateAndCount(surveyDateAndCountArray)
  }

  const getDateArray = (surveyDateAndCountArray: ISurveyDataAndCount[]) => {
    if (!surveyDataAndCount || surveyDataAndCount?.length === 0) return []
    return surveyDateAndCountArray?.map(({date}) => new Date(date))
  }

  return {
    selectedDate,
    dateLoading,
    surveyDataAndCount,
    changeSelectedDate,
    changeSurveyDateAndCount,
    getDateArray,
    changeDateLoading,
  }
}
