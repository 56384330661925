import React, {HTMLAttributes, ReactNode} from 'react'
interface AppLabelElementContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}
const AppLabelElementContainer = ({
  children,
  className = '',
  ...remainingProps
}: AppLabelElementContainerProps) => {
  return (
    <div
      className={`fv-row d-flex flex-column justify-content-center align-content-center gap-2 ${className}`}
      {...remainingProps}
    >
      <>{children}</>
    </div>
  )
}

export default AppLabelElementContainer
