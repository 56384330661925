import React, {useEffect, useState} from 'react'
import AppSubmitButtonContainer from '../../../components/AppSubmitButtonContainer'
import AppButton from '../../../components/AppButton'
import AppModal from '../../../components/AppModal/AppModal'
import AppDateRangePicker from '../../../components/AppDateRange'
import {useDateRangeState} from '../../../hooks/useDateRange'
import moment from 'moment'
import {useModal} from '../../../hooks/useModal'
import {initialPileHealthState} from './utils'
import PileHealthParametersTable from './components/pileHealthParametersTable'
import {addDays} from 'date-fns'
import {appRoutes} from '../../../routing/routes'
import {useNavigate} from 'react-router-dom'
import {getDateInDefaultFormat} from '../../../Utils/getTokenDecoded'
import {AppToast} from '../../../Utils/AppToast'
import {fetchPIleHealthCount} from './service'
import {initialPileHealthStateType} from './model'

type Props = {}

const parameters = [
  {name: 'Combined Piles', count: 0},
  {name: 'Standing Water', count: 0},
  {name: 'Vegetation Growth', count: 0},
  {name: 'Obstruction Structure', count: 0},
  {name: 'Tarpaulin On Pile', count: 0},
]

const ShowPileHealth = () => {
  const {toast} = AppToast
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [pileCountState, setPileCountState] =
    useState<initialPileHealthStateType>(initialPileHealthState)
  const {
    closeModal: closeDateRangeModal,
    isModalVisible: isDateRangeModalVisible,
    openModal: openDateRangeModal,
  } = useModal()
  const {dateState, handleChange, setDateIsFiltered} = useDateRangeState(
    new Date(),
    addDays(new Date(), 30)
  )
  const startDate = dateState[0]?.startDate
  const endDate = dateState[0]?.endDate
  const dateRangeBtnText =
    startDate && endDate
      ? `${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}`
      : 'Choose date range'
  const handleCloseDateRangeModal = () => {
    closeDateRangeModal()
  }
  const handleDateFilter = async () => {
    setDateIsFiltered()
    closeDateRangeModal()
    if (!startDate || !endDate) return
    getPileHealthCount(startDate, endDate)
  }

  const navigateToSurveysByFilter = (navigationPath: string) => {
    navigate(navigationPath)
  }

  const handlePileCountClick = (pileHealthType: string) => {
    if (!startDate || !endDate) {
      toast.warn('Select date range')
      return
    }
    const startDateString = getDateInDefaultFormat(startDate)
    const endDateString = getDateInDefaultFormat(endDate)
    const searchParamsString = `?parameter=${pileHealthType}&startDate=${startDateString}&endDate=${endDateString}`
    const navigationPath = `${appRoutes.surveysByFilters}${searchParamsString}`
    // console.log({pileHealthType, startDate, endDate})
    navigateToSurveysByFilter(navigationPath)
  }

  const getPileHealthCount = async (startDate: Date, endDate: Date) => {
    try {
      setLoading(true)
      const {data} = await fetchPIleHealthCount(startDate, endDate)
      const {
        combinedPilesCount,
        obstructionStructureCount,
        standingWaterCount,
        tarpaulinOnPileCount,
        vegetationGrowthCount,
      } = data
      setPileCountState((prev) => ({
        ...prev,
        combinedPiles: {...prev.combinedPiles, count: combinedPilesCount},
        obstructionStructure: {...prev.obstructionStructure, count: obstructionStructureCount},
        standingWater: {...prev.standingWater, count: standingWaterCount},
        tarpaulinOnPile: {...prev.tarpaulinOnPile, count: tarpaulinOnPileCount},
        vegetationGrowth: {...prev.vegetationGrowth, count: vegetationGrowthCount},
      }))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!startDate || !endDate) return
    getPileHealthCount(startDate, endDate)
  }, [])

  return (
    <div className='bg-light p-4 rounded'>
      <h3 className='text-dark'>Pile Health</h3>
      <AppSubmitButtonContainer>
        <AppButton label={dateRangeBtnText} onClick={openDateRangeModal} />
      </AppSubmitButtonContainer>
      {isDateRangeModalVisible && (
        <AppModal
          headerText='Select Date Range'
          onClose={handleCloseDateRangeModal}
          visible={isDateRangeModalVisible}
          Footer={() => <AppButton onClick={() => handleDateFilter()} label={'Done'} />}
        >
          <div className='d-flex justify-content-center align-items-center'>
            <AppDateRangePicker
              isVisible={isDateRangeModalVisible}
              dateState={dateState}
              onChange={(item) => handleChange(item)}
            />
          </div>
        </AppModal>
      )}
      <PileHealthParametersTable
        pileCountData={pileCountState}
        loading={loading}
        onPileCountClick={handlePileCountClick}
      />
    </div>
  )
}

export default ShowPileHealth
