import {createContext, ReactNode, useContext, useReducer, useRef, useState} from 'react'

import {AppToast} from '../Utils/AppToast'
import {useFileUpload} from '../hooks/useFileUpload'
import {ISurveyPhotoUpload} from '../modules/MaterialDashboard/model'
import {fileUplaodStatus} from '../Utils/constants'
import {uploadSurveyFile} from '../modules/MaterialDashboard/service'
import {uploadFiles} from '../../config/aws-client-config'
import {
  uploadFileToGCP,
  uploadSurveyFileForStockyard,
} from '../modules/MaterialDashboard/PileManagement/service'
import {fileCreateRecrodRequestForStockyard} from '../modules/MaterialDashboard/PileManagement/Models/apiModel'
import {getFileUploadUrlForOutdoorStockyard} from '../modules/MaterialDashboard/PileManagement/Utils/uploadFiles'

interface IFileUploaderProviderContext {
  children: ReactNode
}

export interface IHandleAfterUpload {
  fileName: string
  fileType: string
  fileExtension: string
  selectedSurveyDate: Date
  selectedSurveyId: string
  stockyardId: string
}

export interface IFileUploadToAWS extends IHandleAfterUpload {
  file: File
}

export interface IFileUploadToGCP extends IHandleAfterUpload {
  file: File
}

export interface IFileUploadForOutdoorStockyard extends fileCreateRecrodRequestForStockyard {
  file: File
}

interface IFileUploadContext {
  fileCount: number
  noOfFilesUploading: number
  noOfFilesUploaded: number
  isFileUploading: boolean
  totalFileProgress: number
  fileProgress: number[]
  file: File | null
  fileExtension: string
  fileType: string
  loading: boolean
  isUploadBtnDisabled: boolean
  fileUploadingMessage: string
  resetState: () => void
  canFileBeUploaded: () => boolean
  handleFileTypeChange: (fileType: string) => void
  handleFileChange: (file: File) => void
  handleSuccessfulFileUpload: (data: IHandleAfterUpload) => void
  uploadToGCP: (data: IFileUploadToGCP) => void
  uploadFilesToOutdoorStockyard: (
    data: IFileUploadForOutdoorStockyard
  ) => Promise<{success: boolean}>
  setFileCount: (fileCount: number) => void
}

const initialState: IFileUploadContext = {
  fileCount: 0,
  noOfFilesUploading: 0,
  noOfFilesUploaded: 0,
  isFileUploading: false,
  totalFileProgress: 0,
  fileProgress: [],
  file: null,
  fileExtension: '',
  fileType: '',
  loading: false,
  isUploadBtnDisabled: false,
  canFileBeUploaded: () => false,
  fileUploadingMessage: '',
  handleFileChange: (file: File) => {},
  handleFileTypeChange: (fileType: string) => {},
  resetState: () => {},
  handleSuccessfulFileUpload: (data: IHandleAfterUpload) => {},
  uploadToGCP: (data: any) => {},
  uploadFilesToOutdoorStockyard: (data: IFileUploadForOutdoorStockyard) =>
    Promise.resolve({success: false}),
  setFileCount: (fileCount: number) => {},
}

interface IFileUploadingInitialState
  extends Pick<
    IFileUploadContext,
    | 'fileProgress'
    | 'noOfFilesUploading'
    | 'noOfFilesUploaded'
    | 'totalFileProgress'
    | 'isFileUploading'
  > {}

const fileUploadingInitialState: IFileUploadingInitialState = {
  fileProgress: [],
  noOfFilesUploading: 0,
  totalFileProgress: 0,
  noOfFilesUploaded: 0,
  isFileUploading: false,
}

const FileUploadContext = createContext<IFileUploadContext>(initialState)

export const useFileUploadContext = () => useContext(FileUploadContext)

const FileUploadContextProvider = ({children}: IFileUploaderProviderContext) => {
  const {toast} = AppToast
  const fileCountRef = useRef(0)
  const fileCount = fileCountRef?.current ? fileCountRef.current : 0
  const [fileUploadingMessage, setFileUploadingMessage] = useState('')

  const [state, setState] = useReducer(
    (
      state: IFileUploadingInitialState,

      fields:
        | Partial<IFileUploadingInitialState>
        | {fileProgressNumber: number}
        | {filesInNumber: number}
    ) => {
      if ('fileProgressNumber' in fields)
        return {
          ...state,
          fileProgress: [...state.fileProgress, fields.fileProgressNumber],
        }
      if ('filesInNumber' in fields) {
        return {
          ...state,
          noOfFilesUploading: state.noOfFilesUploading + fields.filesInNumber,
        }
      }
      return {...state, ...fields}
    },
    fileUploadingInitialState
  )
  const {fileProgress, noOfFilesUploading, noOfFilesUploaded, totalFileProgress, isFileUploading} =
    state
  const {
    fileUploadState,
    isUploadBtnDisabled,
    handleFileChange,
    resetState,
    canFileBeUploaded,
    handleFileTypeChange,
    resetLoading,
    setLoading,
  } = useFileUpload()
  const {file, fileExtension, fileType, loading} = fileUploadState

  const setFileCount = (fileCount: number) => {
    if (fileCountRef.current < 0) {
      fileCountRef.current = 0
      return
    }
    fileCountRef.current = fileCountRef.current + fileCount
  }

  const handleSuccessfulFileUpload = async ({
    fileName,
    fileType,
    selectedSurveyDate,
    selectedSurveyId,
    fileExtension,
    stockyardId,
  }: IHandleAfterUpload) => {
    try {
      setLoading()
      const requestObj: ISurveyPhotoUpload = {
        date: new Date(selectedSurveyDate),
        fileName,
        fileType,
        status: fileUplaodStatus.UploadToGCPFromFronEnd,
        fileExtension,
        surveys: [{surveyId: selectedSurveyId}],
      }
      const response = await uploadSurveyFile(stockyardId, requestObj)
      if (response) {
        setState({filesInNumber: -1})
        resetState()
        toast.success('File uploaded successfully')
        setFileCount(-1)
      }
    } catch (error) {
      console.error('upload to server after aws file upload', error)
    } finally {
      resetLoading()
    }
  }

  const uploadToGCP = async ({
    fileName,
    file,
    fileType,
    fileExtension,
    selectedSurveyDate,
    selectedSurveyId,
    stockyardId,
  }: IFileUploadToGCP) => {
    try {
      setLoading()
      setState({filesInNumber: 1})
      const {data} = await uploadFileToGCP(stockyardId, file, selectedSurveyDate)
      if (data.publicUrl)
        await handleSuccessfulFileUpload({
          fileName,
          fileType,
          selectedSurveyDate,
          selectedSurveyId,
          fileExtension,
          stockyardId,
        })
    } catch (error: any) {
      console.error('file context file upload error', error)
      setState({filesInNumber: -1})
      resetState()
      toast.error('File not uploaded, Something went wrong')
    } finally {
      resetLoading()
      setState({isFileUploading: false})
    }
  }

  const uploadFilesToOutdoorStockyard = async ({
    date,
    file,
    fileExtension,
    fileName,
    fileType,
    status,
    stockyardId,
    surveys,
  }: IFileUploadForOutdoorStockyard): Promise<{success: boolean}> => {
    try {
      const fileUploadMsg = `Uploading ${fileType} file`
      const successMsg = `${fileType} uploaded successfully`
      const fileNotUploadedMsg = 'File not uploaded, Please try again'
      const filesNotCreatedMsg = 'Files were not created in surveys'
      setFileUploadingMessage(fileUploadMsg)
      const fileUploadUrl = await getFileUploadUrlForOutdoorStockyard({date, file, stockyardId})
      if (!fileUploadUrl) {
        toast.error(fileNotUploadedMsg)
        return {success: false}
      }
      const response = await uploadSurveyFileForStockyard({
        date,
        fileExtension,
        fileName,
        fileType,
        status,
        stockyardId,
        surveys,
      })
      if (!response) {
        toast.error(filesNotCreatedMsg)
        return {success: false}
      }
      toast.success(successMsg)
      return {success: true}
    } catch (error) {
      console.error('file upload for outdoor stockyard error', error)
      return {success: false}
    } finally {
      setFileUploadingMessage('')
    }
  }

  return (
    <FileUploadContext.Provider
      value={{
        loading,
        isUploadBtnDisabled,
        noOfFilesUploading,
        noOfFilesUploaded,
        isFileUploading,
        totalFileProgress,
        file,
        fileExtension,
        fileProgress,
        fileType,
        fileCount,
        fileUploadingMessage,
        canFileBeUploaded,
        handleSuccessfulFileUpload,
        handleFileChange,
        handleFileTypeChange,
        resetState,
        uploadToGCP,
        setFileCount,
        uploadFilesToOutdoorStockyard,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  )
}
export default FileUploadContextProvider
