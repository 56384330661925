import Spinner from 'react-bootstrap/Spinner'
interface AppLoaderProps {
  loading: boolean
}
const AppLoader = ({loading}: AppLoaderProps) => {
  return loading ? (
    <Spinner animation='border' variant='warning' className='mx-auto'>
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
  ) : (
    <></>
  )
}

export default AppLoader
