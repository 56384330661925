export const datesMetron = '/datesMetron'
export const datesMetronCompany = 'datesMetronClients'
export const datesMetronAnalystSupervisor = 'AnalystSupervisor'
export const company = '/company'
export const users = 'users'
export const location = 'location'
export const department = 'department'
export const stockyards = 'stockyards'
export const materialDashboard = '/materialDashboard'
export const bulkMaterial = 'bulkMaterial'
export const surveys = 'surveys'
export const surveysByFilters = 'surveysByFilters'
export const addSurvey = 'addSurvey'
export const pileAssignmentRoute = 'pileAssignment'
export const pileVolumetricAnalysisRoute = 'pileVolumetricAnalysis'
export const supervisorDashboardRoute = 'supervisorDashboard'
export const viewOrthoRoute = 'viewOrtho'
export const viewPointCloudRoute = 'viewPointCloud'
export const reports = '/reports'
export const advanceReports = 'advanceReports'
export const reconcilliation = 'reconcilliation'
export const timelineReport = 'timelineReport'
export const pilesReport = 'pilesReports'
export const ticketReport = 'ticketReport'
export const departmentWiseReport = 'departmentWiseReport'
export const stockyardWiseReport = 'stockyardWiseReport'
export const locationWithDateWiseReport = 'locationWithDateWiseReport'
export const biScreen = 'biScreen'
export const physicalVerification = 'physicalVerification'
export const changePassword = '/changePassword'
export const realTimeMeasurements = '/realTimeMeasurements'
export const lidarMeasurement = 'lidarMeasurement'
export const cctvCameraMeasurement = 'cctvCameraMeasurement'
export const selfMeasurement = 'selfMeasurement'
export const analysis = '/analysis'
export const stockyardAnalysis = 'stockyardAnalysis'
export const fragmentationAnalysis = 'fragmentationAnalysis'

export const appRoutes = {
  auth: 'auth',
  dashboard: 'dashboard',
  datesMetron,
  datesMetronCompany: `${datesMetron}/${datesMetronCompany}`,
  datesMetronAnalystSupervisor: `${datesMetron}/${datesMetronAnalystSupervisor}`,
  company,
  users: `${company}/${users}`,
  location: `${company}/${location}`,
  department: `${company}/${department}`,
  stockyards: `${company}/${stockyards}`,
  materialDashboard,
  bulkMaterial: `${materialDashboard}/${bulkMaterial}`,
  surveys: `${materialDashboard}/${surveys}`,
  surveysByFilters: `${materialDashboard}/${surveysByFilters}`,

  addSurvey: `${materialDashboard}/${addSurvey}`,
  pileAssignment: `${materialDashboard}/${pileAssignmentRoute}`,
  pileVolumetricAnalysisRoute: `${materialDashboard}/${pileVolumetricAnalysisRoute}`,
  supervisorDashboard: `${materialDashboard}/${supervisorDashboardRoute}`,
  viewOrtho: `${materialDashboard}/${viewOrthoRoute}`,
  viewPointCloud: `${materialDashboard}/${viewPointCloudRoute}`,
  reports,
  advanceReports: `${reports}/${advanceReports}`,
  departmentWiseReport: `${reports}/${departmentWiseReport}`,
  stockyardWiseReport: `${reports}/${stockyardWiseReport}`,
  locationWithDateWiseReport: `${reports}/${locationWithDateWiseReport}`,
  biScreen: `${reports}/${biScreen}`,
  physicalVerification: `${reports}/${physicalVerification}`,
  reconcilliation: `${reports}/${reconcilliation}`,
  timelineReport: `${reports}/${timelineReport}`,
  pilesReports: `${reports}/${pilesReport}`,
  ticketReport: `${reports}/${ticketReport}`,
  changePassword,
  realTimeMeasurements,
  lidarMeasurement: `${realTimeMeasurements}/${lidarMeasurement}`,
  cctvCameraMeasurement: `${realTimeMeasurements}/${cctvCameraMeasurement}`,
  selfMeasurement: `${realTimeMeasurements}/${selfMeasurement}`,
  analysis,
  stockyardAnalysis: `${analysis}/${stockyardAnalysis}`,
  fragmentationAnalysis: `${analysis}/${fragmentationAnalysis}`,
}
