import clsx from 'clsx'
import {ChangeEvent, FC, SelectHTMLAttributes} from 'react'
import Form from 'react-bootstrap/Form'

export interface OptionObjInterface {
  value: string
  label: string
}

export interface AppSelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  selectSize?: 'sm' | 'lg' // Renamed to avoid conflict with HTML select size attribute
  placeholder: string
  data: OptionObjInterface[]
  onChange: (value: any) => void
}

const AppSelect: FC<AppSelectProps> = ({
  selectSize = 'lg',
  placeholder,
  data,
  className = '',
  onChange = (value: any) => {},
  ...remainingProps
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
  }
  return (
    <Form.Select
      aria-label='Default select example'
      className={clsx('form-control bg-white', className, {
        'form-select-sm': selectSize === 'sm',
        'form-select-lg': selectSize === 'lg',
      })}
      onChange={handleChange}
      {...remainingProps}
    >
      <option hidden value=''>
        {placeholder}
      </option>
      {data?.map((ele) => (
        <option value={ele.value} key={ele.value}>
          {ele.label}
        </option>
      ))}
    </Form.Select>
  )
}

export default AppSelect
