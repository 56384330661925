import {useEffect, useMemo, useState} from 'react'
import {OptionObjInterface} from '../../components/AppSelect'
import {useAuth, userRoleObj} from '../auth'
import {getAllLocations, getAllStockyards, getLocationById} from '../company/services'
import {ILocationFromServer, IStockyardFromServer} from '../company/model'
import SelectElement from './AddBulkMaterial/SelectElement'
import NameElement from './AddBulkMaterial/NameElement'
import {getCompanyStockyardsByLocationId} from './PileManagement/service'
import {ICompanyStockyardFromServer} from './PileManagement/Models/apiModel'
import {useRole} from '../../hooks/useRole'
import {useLocation} from 'react-router-dom'
import {getLocationLocalStorageKey, getstockyardLocalStorageKey} from '../../Utils/getTokenDecoded'
interface SurveyLocationStockyardProps {
  stockyardValue: string
  locationValue: string
  onLocationChange: (location: any) => void
  onStockyardChange: (
    stockyard: any,
    list?: ICompanyStockyardFromServer[] | IStockyardFromServer[]
  ) => void
}

const SurveyLocationStockyard = ({
  locationValue,
  stockyardValue,
  onLocationChange,
  onStockyardChange,
}: SurveyLocationStockyardProps) => {
  const {pathname} = useLocation()
  const {userRole, locationId} = useAuth()

  const [locationData, setLocationData] = useState<OptionObjInterface[]>([])
  const [locationName, setLocationName] = useState('')
  const [locationLoading, setLocationLoading] = useState(false)

  const [stockyardData, setStockyardData] = useState<
    ICompanyStockyardFromServer[] | IStockyardFromServer[]
  >([])
  const [stockyardName, setStockyardName] = useState('')
  const [stockyardLoading, setStockyardLoading] = useState(false)

  const isCompanyAdmin = userRole === userRoleObj.CompanyAdmin
  const isLocationHead = userRole === userRoleObj.LocationHead
  const isDepartmentUser = userRole == userRoleObj.User
  const isStockyardVisible = isDepartmentUser ? true : locationValue ? true : false

  const stockyardDropdownData: OptionObjInterface[] = useMemo(
    () => stockyardData.map(({id, name}) => ({label: name, value: id})),
    [stockyardData]
  )

  const fetchStockyardsByLocationId = async (locationId: string) => {
    setStockyardLoading(true)
    try {
      const {data} = await getCompanyStockyardsByLocationId(locationId)
      setStockyardData(data)
      if (isDepartmentUser && data?.length > 0) {
        setStockyardName(data[0]?.name)
        handleStockyardChange(data[0]?.id)
      }
    } catch (error) {
      console.error('all stockards list error', error)
    } finally {
      setStockyardLoading(false)
    }
  }

  const handleLocationChange = (locationId: string) => {
    const locationLocalStorageKey = getLocationLocalStorageKey(pathname)
    localStorage.setItem(locationLocalStorageKey, locationId)
    onLocationChange(locationId)
    fetchStockyardsByLocationId(locationId)
  }

  const handleStockyardChange = (stockyardId: string) => {
    const stockyardLocalStorageKey = getstockyardLocalStorageKey(pathname)
    localStorage.setItem(stockyardLocalStorageKey, stockyardId)
    onStockyardChange(stockyardId, stockyardData)
  }

  const setFilterDataFromLocalStorage = () => {
    const locationLocalStorageKey = getLocationLocalStorageKey(pathname)
    const locationIdFromLocalStorage = localStorage.getItem(locationLocalStorageKey) || ''
    const stockyardLocalStorageKey = getstockyardLocalStorageKey(pathname)
    const stockyardIdFromLocalStorage = localStorage.getItem(stockyardLocalStorageKey) || ''
    if (!!locationIdFromLocalStorage) handleLocationChange(locationIdFromLocalStorage)
    if (!!stockyardIdFromLocalStorage) {
      handleStockyardChange(stockyardIdFromLocalStorage)
    }
  }

  useEffect(() => {
    const fetchAllLocations = async () => {
      setLocationLoading(true)
      try {
        const {data} = await getAllLocations<ILocationFromServer>()
        setLocationData(data.map((location) => ({label: location.name, value: location.id})))
      } catch (error) {
        console.log('get all location error', error)
      } finally {
        setLocationLoading(false)
      }
    }

    const fetchLocationById = async (locationId: string) => {
      setLocationLoading(true)
      try {
        const {data} = await getLocationById<ILocationFromServer>(locationId)
        setLocationData([{label: data.name, value: data.id}])
        setLocationName(data.name)
        handleLocationChange(data.id)
      } catch (error) {
        console.error('get location by id error', error)
      } finally {
        setLocationLoading(false)
      }
    }
    setFilterDataFromLocalStorage()
    if (isCompanyAdmin) fetchAllLocations()
    else if (isLocationHead) fetchLocationById(locationId)
  }, [])

  useEffect(() => {
    if (!locationValue) return
    const fetchAllStockyards = async () => {
      setStockyardLoading(true)
      try {
        const {data} = await getAllStockyards<IStockyardFromServer>()
        setStockyardData(data)
        if (isDepartmentUser && data?.length > 0) {
          setStockyardName(data[0]?.name)
          handleStockyardChange(data[0]?.id)
        }
      } catch (error) {
        console.error('all stockards list error', error)
      } finally {
        setStockyardLoading(false)
      }
    }
    if (isDepartmentUser) fetchAllStockyards()
  }, [])
  return (
    <div className='my-1'>
      <div className='d-flex justify-content-center align-items-center p-4 gap-5 fv-row mb-8 flex-wrap flex-md-nowrap'>
        {isLocationHead ? (
          <>
            <NameElement label={'Location'} value={locationName} isVisibie={isLocationHead} />
          </>
        ) : (
          <SelectElement
            value={locationValue}
            data={locationData}
            label='Location'
            loading={locationLoading}
            onChange={(value: any) => handleLocationChange(value)}
            placeholder='Select Location'
          />
        )}
        {isStockyardVisible && (
          <>
            {!isDepartmentUser ? (
              <SelectElement
                data={stockyardDropdownData}
                placeholder='Select Stockyard'
                value={stockyardValue}
                onChange={handleStockyardChange}
                label={'Stockyard'}
                loading={stockyardLoading}
              />
            ) : (
              <NameElement label={'Stockyard'} value={stockyardName} isVisibie={true} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SurveyLocationStockyard
