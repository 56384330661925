import {IEditCell} from '@syncfusion/ej2-react-grids'
import {Column} from '../../../../components/AppDataTable'
import {OptionObjInterface} from '../../../../components/AppSelect'
import {DataManager, Query} from '@syncfusion/ej2-data'
//volumetric Analyst

export interface IVolumeHealth {
  pileHealth: boolean
  combinedPiles: boolean
  standingWater: boolean
  vegetationGrowth: boolean
  obstructionStructure: boolean
  tarpaulinOnPile: boolean
}
export type IHealth = keyof IVolumeHealth
export interface IHealthObj {
  label: string
  value: IHealth
}
export const healthArray: IHealthObj[] = [
  {label: 'Combined Piles', value: 'combinedPiles'},
  {label: 'Obst Structure', value: 'obstructionStructure'},
  {label: 'Pile Health', value: 'pileHealth'},
  {label: 'Standing Water', value: 'standingWater'},
  {label: 'Tarpauline on Pile', value: 'tarpaulinOnPile'},
  {label: 'Vegetation Growth', value: 'vegetationGrowth'},
]
export const healthValueArray: IHealth[] = healthArray.map(({value}) => value)
export const healthObj: Record<IHealth, boolean> = {
  combinedPiles: false,
  obstructionStructure: false,
  pileHealth: false,
  standingWater: false,
  tarpaulinOnPile: false,
  vegetationGrowth: false,
}
export const healthLabelObj: Record<IHealth, string> = {
  combinedPiles: 'Combined Piles',
  obstructionStructure: 'Obstruction Structure',
  pileHealth: 'Pile Health',
  standingWater: 'Standing Water',
  tarpaulinOnPile: 'Tarpauline On Pile',
  vegetationGrowth: 'Vegetation Growht',
}
export type ConfidenceType = 'High' | 'Medium' | 'Low'

export interface ISnaps {
  demSnap: boolean
  orthoSnap: boolean
  volumetricComp: boolean
  picSnap: boolean
  pointCloud: boolean
  demTiff: boolean
  orthoTiff: boolean
  pointCloudLas: boolean
}

export const snapsObj: Record<keyof ISnaps, keyof ISnaps> = {
  demSnap: 'demSnap',
  orthoSnap: 'orthoSnap',
  picSnap: 'picSnap',
  volumetricComp: 'volumetricComp',
  demTiff: 'demTiff',
  orthoTiff: 'orthoTiff',
  pointCloud: 'pointCloud',
  pointCloudLas: 'pointCloudLas',
}

const normalImagesExtensionStrng = '.jpeg,.jpg,.png'
const tiffImageExtensionString = '.tif,.tiff'
const pointCloudExtensionString = '.las,.laz'
export const shapeFileExtensionString = '.zip'

export const fileAcceptStringObj = {
  [snapsObj.demSnap]: normalImagesExtensionStrng,
  [snapsObj.orthoSnap]: normalImagesExtensionStrng,
  [snapsObj.picSnap]: normalImagesExtensionStrng,
  [snapsObj.volumetricComp]: normalImagesExtensionStrng,
  [snapsObj.demTiff]: tiffImageExtensionString,
  [snapsObj.orthoTiff]: tiffImageExtensionString,
  [snapsObj.pointCloud]: pointCloudExtensionString,
  [snapsObj.pointCloudLas]: pointCloudExtensionString,
}

export interface IVolumetricAnalysisList extends IVolumeHealth, ISnaps {
  id: string
  bulkMaterialName: string
  pileCode: string
  pileName: string
  locationName: string
  stockyard: string
  derivedErpQuantity: number
  volume: number
  additionalInformation: string
  toeConfidence: ConfidenceType
  surfaceConfidence: ConfidenceType
  date: Date
  health: string[]
}

export interface IVolumetricAnalysisColumn extends Column {
  field: keyof IVolumetricAnalysisList
}
export type ConfidenceFieldType = keyof Pick<
  IVolumetricAnalysisList,
  'toeConfidence' | 'surfaceConfidence'
>

export interface IConfidence extends OptionObjInterface {
  label: ConfidenceType
  value: ConfidenceType
}
export const ConfidenceLabelValueList: IConfidence[] = [
  {label: 'Low', value: 'Low'},
  {label: 'Medium', value: 'Medium'},
  {label: 'High', value: 'High'},
]

const confidenceParams: IEditCell = {
  params: {
    actionComplete: () => false,
    dataSource: new DataManager(ConfidenceLabelValueList),
    fields: {text: 'label', value: 'value'},
    query: new Query(),
  },
}

export const confidenceColumnLabelValueArray: {label: ConfidenceFieldType; headerText: string}[] = [
  {label: 'toeConfidence', headerText: 'Toe Confidence'},
  {label: 'surfaceConfidence', headerText: 'Surface Confidence'},
]

const confidenceColumns: IVolumetricAnalysisColumn[] = confidenceColumnLabelValueArray.map(
  ({label, headerText}) => ({
    field: label,
    headerText,
    width: '63',
    editType: 'dropDownEdit',
    edit: confidenceParams,
  })
)

export const volumetricAnalysisColumn: IVolumetricAnalysisColumn[] = [
  {field: 'volume', headerText: 'Volume', width: '63', editType: 'numericEdit'},
  {field: 'additionalInformation', headerText: 'Add Info', width: '63'},
  ...confidenceColumns,
]

export type volumetricAnalysisDataInitialStateType = {
  list: IVolumetricAnalysisList[]
  loading: boolean
}
export const volumetricAnalysisDataInitialState: volumetricAnalysisDataInitialStateType = {
  list: [],
  loading: false,
}

export interface ISelectedSurvey {
  surveyId: string
  surveyDate: null | Date
  fileType: string
  status: keyof ISnaps | null
}
export const selectedSurveyInitialState: ISelectedSurvey = {
  surveyDate: null,
  surveyId: '',
  fileType: '',
  status: null,
}

export type shapeFileType = {
  name: string
}

export const sampShapeFilesList: shapeFileType[] = [
  {name: 'fileA'},
  {name: 'fileB'},
  {name: 'fileC'},
]
