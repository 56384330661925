import React from 'react'
import {initialPileHealthStateType} from '../model'
import {useThemeMode} from '../../../../../_metronic/partials'

type Props = {
  pileCountData: initialPileHealthStateType
  loading: boolean
  onPileCountClick: (pileHealthType: string) => void
}

const PileCountHeader = ({isDarkMode}: {isDarkMode: boolean}) => {
  return (
    <>
      <div
        className='fw-bold text-center p-2 rounded-top'
        style={{
          backgroundColor: isDarkMode ? '#fff' : '#333',
          color: isDarkMode ? '#333' : '#fff',
        }}
      >
        Parameter
      </div>
      <div
        className='fw-bold text-center p-2 rounded-top'
        style={{
          backgroundColor: isDarkMode ? '#fff' : '#333',
          color: isDarkMode ? '#333' : '#fff',
        }}
      >
        Count
      </div>
    </>
  )
}

const PileHealthParametersTable = ({pileCountData, loading, onPileCountClick}: Props) => {
  const {menuMode, mode} = useThemeMode()
  const isDarkMode = mode === 'dark'
  const parameters = Object.entries(pileCountData).map(([key, obj]) => ({
    value: key,
    name: obj.name,
    count: obj.count,
  }))

  const showLoadingAndCount = (count: number, loading: boolean) => {
    if (loading) return 'Updating...'
    return count
  }

  const handlePileCountClick = (pileHealthType: string) => {
    if (loading) return
    onPileCountClick(pileHealthType)
  }

  return (
    <div className='d-flex justify-content-between w-100 mt-3 mb-3 p-4 bg-light rounded shadow-lg'>
      <div className='w-50 mx-2 px-3 py-2 rounded'>
        <div
          className='d-grid'
          style={{gridTemplateColumns: '1fr 1fr', rowGap: '10px', columnGap: '20px'}}
        >
          <PileCountHeader isDarkMode={isDarkMode} />
          {parameters.slice(0, 3).map((param, index) => (
            <React.Fragment key={index}>
              <div className='p-2 border-bottom bg-light text-center text-dark'>{param.name}</div>
              <div
                className={`p-2 border-bottom text-center`}
                style={{cursor: loading ? '' : 'pointer'}}
                onClick={() => !loading && handlePileCountClick(param.value)}
              >
                {showLoadingAndCount(param.count, loading)}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className='w-50 mx-2 px-3 py-2 rounded'>
        <div
          className='d-grid'
          style={{gridTemplateColumns: '1fr 1fr', rowGap: '10px', columnGap: '20px'}}
        >
          <PileCountHeader isDarkMode={isDarkMode} />
          {parameters.slice(3).map((param, index) => (
            <React.Fragment key={index}>
              <div className='p-2 border-bottom bg-light text-center text-dark'>{param.name}</div>
              <div
                className={`p-2 border-bottom text-center`}
                style={{cursor: loading ? '' : 'pointer'}}
                onClick={() => !loading && handlePileCountClick(param.value)}
              >
                {showLoadingAndCount(param.count, loading)}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PileHealthParametersTable
