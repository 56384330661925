import {BASE_URL} from '../../../Utils/constants'
import axios from 'axios'
import {getISOString} from '../../../Utils/getTokenDecoded'
import {pileCountFromServer} from './model'

const PILE_HEALTH_COUNT_URL = `${BASE_URL}/reports/advanced-report-count`

export const fetchPIleHealthCount = (startDate: Date, endDate: Date) => {
  const url = PILE_HEALTH_COUNT_URL
  return axios.post<pileCountFromServer>(url, {
    startDate: getISOString(startDate),
    endDate: getISOString(endDate),
  })
}
