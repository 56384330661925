import {useState} from 'react'

import {useLocationStockyard} from '../../../hooks/useLocationStockyard'
import SurveyLocationStockyard from '../../MaterialDashboard/SurveyLocationStockyard'
import AppSelect, {OptionObjInterface} from '../../../components/AppSelect'
import AppDatePicker from '../../../components/AppDatePicker'
import DatePickerWithRemoveFilterBtn from '../../MaterialDashboard/Survey/DatePickerWithRemoveFilterBtn'
import {ISurveyDataAndCount, ISurveyList} from '../../MaterialDashboard/model'
import {AppToast} from '../../../Utils/AppToast'
import {checkIsSameDate} from '../../../Utils/getTokenDecoded'
import {AppSelectLocationDepartment} from '../../MaterialDashboard/AddBulkMaterial/AddBulkMaterialSelectLocationDepartment'
import {useLocationDpartment} from '../../../hooks/useLocationDepartment'
import {getSurveysByDepartmentId} from '../../MaterialDashboard/service'
import {getISurveylistDataFromGetSurveyData} from '../../MaterialDashboard/utils'
import {Container, Row, Col} from 'react-bootstrap'
import ShowPileHealth from './ShowPileHealth'
import ShowLastMonthSurveyDates from './ShowLastMonthSurveyDates'
import ShowRealTimeMeasurements from './ShowRealTimeMeasurements'
import ShowStockyardAnalysis from './ShowStockyardAnalysis'

type Props = {}

{
  /**
  Slope Stability:, Boundary and Buffer Zones:Material Segregation:
fuel consumption,  cycle times, consumption rates, Temperature Monitoring (coal/coke)
  */
}

const stockyardParameters: OptionObjInterface[] = [
  {label: 'Slope Stability', value: 'slopeStability'},
  {label: 'Boundary and Buffer Zones', value: 'boundaryBufferZone'},
  {label: 'Material Segregation', value: 'materialSegregation'},
  {label: 'Fuel consumption', value: 'fuelConsumption'},
  {label: 'Cycle times', value: 'cycleTimes'},
  {label: 'Consumption rates', value: 'consumptionRates'},
  {label: 'Temperature Monitoring (coal/coke)', value: 'temperatureMonitoring'},
]

const ClientDashboard = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={6} xs={12} className='p-4'>
          <ShowPileHealth />
          <ShowLastMonthSurveyDates />
        </Col>

        <Col md={6} xs={12} className='p-4'>
          <ShowStockyardAnalysis />
          <ShowRealTimeMeasurements />
        </Col>
      </Row>
    </Container>
  )
}

export default ClientDashboard

const styles = {
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  tableSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '10px',
    columnGap: '20px',
    width: '45%',
  },
  cell: {
    padding: '8px',
    borderBottom: '1px solid #ddd',
  },
}
