import AppLoader from './AppLoader'
import clsx from 'clsx'
interface AppLoaderProps {
  loading: boolean
  text?: string
}
const AppLoaderWithContainer = ({loading, text = ''}: AppLoaderProps) => {
  const isText = !!text

  return (
    <div className={clsx('d-flex justify-content-center align-items-center', {'gap-5': isText})}>
      {isText && <span>{text}</span>}
      <AppLoader loading={loading} />
    </div>
  )
}

export default AppLoaderWithContainer
