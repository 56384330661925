import {useEffect, useState} from 'react'
import {OptionObjInterface} from '../../../components/AppSelect'

import {useAuth, userRoleObj} from '../../auth'
import {
  getAllLocations,
  getDepartmentByDepartmentId,
  getDepartmentByLocationId,
  getLocationById,
} from '../../company/services'
import {IDepartmentFromServer, ILocationFromServer} from '../../company/model'
import SelectElement from './SelectElement'
import NameElement from './NameElement'
import {useLocation} from 'react-router-dom'
import {
  getdepartmentLocalStorageKey,
  getLocationLocalStorageKey,
} from '../../../Utils/getTokenDecoded'
interface BulkMaterialSelectLocationDepartmentProps {
  departmentValue: string
  locationValue: string
  onLocationChange: (value: any) => void
  onDepartmentChange: (value: any) => void
  selectElementWidth?: number
}

const AddBulkMaterialSelectLocationDepartment = ({
  departmentValue,
  locationValue,
  onDepartmentChange,
  onLocationChange,
  selectElementWidth = 45,
}: BulkMaterialSelectLocationDepartmentProps) => {
  const {pathname} = useLocation()
  const {userRole, locationId, departmentId} = useAuth()
  const checkUserMatch = (currentUserRole: string) => currentUserRole === userRole

  const [locationData, setLocationData] = useState<OptionObjInterface[]>([])
  const [locationName, setLocationName] = useState('')
  const [locationLoading, setLocationLoading] = useState(false)

  const [departmentData, setDepartmentData] = useState<OptionObjInterface[]>([])
  const [departmentName, setDepartmentName] = useState('')
  const [departmentLoading, setDepartmentLoading] = useState(false)

  const {CompanyAdmin, LocationHead, User, DatesmetronAnalyst, Supervisor} = userRoleObj

  const isLocationHead = userRole === LocationHead
  const isDepartmentUser = userRole == User

  const fetchAllLocationsPermittedUsers = [CompanyAdmin, DatesmetronAnalyst, Supervisor]

  const fetchDepartmentByLocationIdPermittedUsers = [
    ...fetchAllLocationsPermittedUsers,
    LocationHead,
  ]
  const isFetchAllLocationsPermitted = fetchAllLocationsPermittedUsers.some(checkUserMatch)
  const isLocationDropdownVisible = isFetchAllLocationsPermitted
  const isFetchDepartmentByLocationIdPermitted =
    fetchDepartmentByLocationIdPermittedUsers.some(checkUserMatch)
  const isDeparmentVisible = isDepartmentUser ? true : locationValue ? true : false

  const handleLocationChange = (locationId: string) => {
    onLocationChange(locationId)
    const locationLocalStorageKey = getLocationLocalStorageKey(pathname)
    localStorage.setItem(locationLocalStorageKey, locationId)
  }
  const handleDepartmentChange = (departmentId: string) => {
    onDepartmentChange(departmentId)
    const departmentLocalStorageKey = getdepartmentLocalStorageKey(pathname)
    localStorage.setItem(departmentLocalStorageKey, departmentId)
  }

  const setFilterDataFromLocalStorage = () => {
    const locationLocalStorageKey = getLocationLocalStorageKey(pathname)
    const departmentLocalStorageKey = getdepartmentLocalStorageKey(pathname)
    const locationIdFromLocalStorage = localStorage.getItem(locationLocalStorageKey) || ''
    const departmentIdFromLocalStorage = localStorage.getItem(departmentLocalStorageKey) || ''
    if (!!locationIdFromLocalStorage) handleLocationChange(locationIdFromLocalStorage)
    if (!!departmentIdFromLocalStorage) {
      handleDepartmentChange(departmentIdFromLocalStorage)
    }
  }

  useEffect(() => {
    const fetchAllLocations = async () => {
      setLocationLoading(true)
      try {
        const {data} = await getAllLocations<ILocationFromServer>()
        setLocationData(data.map((location) => ({label: location.name, value: location.id})))
      } catch (error) {
        console.error('get all location error', error)
      } finally {
        setLocationLoading(false)
      }
    }

    const fetchLocationById = async (locationId: string) => {
      setLocationLoading(true)
      try {
        const {data} = await getLocationById<ILocationFromServer>(locationId)
        setLocationData([{label: data.name, value: data.id}])
        setLocationName(data.name)
        handleLocationChange(data.id)
      } catch (error) {
        console.error('get location by id error', error)
      } finally {
        setLocationLoading(false)
      }
    }

    setFilterDataFromLocalStorage()
    if (isFetchAllLocationsPermitted) fetchAllLocations()
    else if (isLocationHead) fetchLocationById(locationId)
  }, [])

  useEffect(() => {
    const fetchDepartmentByLocationId = async (locationId: string) => {
      setDepartmentLoading(true)
      try {
        const {data} = await getDepartmentByLocationId<IDepartmentFromServer>(locationId)
        setDepartmentData(
          data.map((department) => ({label: department.name, value: department.id}))
        )
      } catch (error) {
        console.error('fetch department by location id error', error)
      } finally {
        setDepartmentLoading(false)
      }
    }
    const fetchDepartmentById = async (departmentId: string) => {
      setDepartmentLoading(true)
      try {
        const {data} = await getDepartmentByDepartmentId<IDepartmentFromServer>(departmentId)
        setDepartmentData([{label: data.name, value: data.id}])
        setDepartmentName(data.name)
        handleDepartmentChange(data.id)
      } catch (error) {
        console.error('get department by department id error', error)
      } finally {
        setDepartmentLoading(false)
      }
    }
    if (locationValue && isFetchDepartmentByLocationIdPermitted)
      fetchDepartmentByLocationId(locationValue)
    else if (isDepartmentUser) fetchDepartmentById(departmentId)
  }, [locationValue])

  return (
    <div className='my-'>
      <div className='d-flex justify-content-center align-items-center p-4 gap-5 mb-8 flex-wrap flex-md-nowrap'>
        {isLocationDropdownVisible ? (
          <>
            <SelectElement
              value={locationValue}
              data={locationData}
              label='Location'
              loading={locationLoading}
              onChange={handleLocationChange}
              placeholder='Select Location'
              width={selectElementWidth}
            />
          </>
        ) : (
          <NameElement
            label={'Location'}
            value={locationName}
            isVisibie={isLocationHead}
            width={selectElementWidth}
          />
        )}
        {isDeparmentVisible && (
          <>
            {!isDepartmentUser ? (
              <SelectElement
                data={departmentData}
                placeholder='Select Department'
                value={departmentValue}
                onChange={handleDepartmentChange}
                label={'Department'}
                loading={departmentLoading}
                width={selectElementWidth}
              />
            ) : (
              <NameElement
                label={'Department'}
                value={departmentName}
                isVisibie={true}
                width={selectElementWidth}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default AddBulkMaterialSelectLocationDepartment

export {AddBulkMaterialSelectLocationDepartment as AppSelectLocationDepartment}
