import React, {ReactNode} from 'react'
import {Modal, ModalFooter} from 'react-bootstrap'
import AppModalHeader, {AppModalHeaderProps} from './AppModalHeader'
interface AppModalProps extends AppModalHeaderProps {
  visible: boolean
  children: ReactNode
  backdrop?: any
  Footer?: () => ReactNode
}

const AppModal = ({
  onClose,
  visible,
  headerText,
  children,
  backdrop = true,
  Footer = () => null,
}: AppModalProps) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={visible}
      onHide={onClose}
      backdrop={backdrop}
    >
      <AppModalHeader headerText={headerText} onClose={onClose} />
      {children}
      <ModalFooter>{Footer()}</ModalFooter>
    </Modal>
  )
}

export default AppModal
