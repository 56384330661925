import {useState} from 'react'

export const useLocationDpartment = () => {
  const [locationId, setLocationId] = useState('')
  const [departmentId, setDepartmentId] = useState('')
  const onLocationChange = (
    locationId: string,
    locationCallback: (locationId: string) => void = (locationId: string) => {}
  ) => {
    setLocationId(locationId)
    locationCallback(locationId)
  }
  const onDepartmentChange = (
    departmentId: string,
    departmentCallback: (departmentId: string) => void = (departmentId: string) => {}
  ) => {
    setDepartmentId(departmentId)
    departmentCallback(departmentId)
  }
  const resetLocationDepartment = () => {
    setLocationId('')
    setDepartmentId('')
  }
  return {locationId, departmentId, onLocationChange, onDepartmentChange, resetLocationDepartment}
}
