interface NameElementProps {
  label: string
  value: string
  isVisibie: boolean
  width?: number
}

const NameElement = ({label, value, isVisibie, width = 45}: NameElementProps) => {
  return isVisibie ? (
    <div
      className='fv-row d-flex flex-column justify-content-center align-content-center gap-5'
      style={{textAlign: 'center', width: `${width}%`}}
    >
      <label>{label}</label>
      <p>{value}</p>
    </div>
  ) : null
}
export default NameElement
