import {pileHealthKeyType, pileHealthObjType} from './model'

export const initialPileHealthState = {
  combinedPiles: {name: 'Combined Piles', count: 0},
  standingWater: {name: 'Standing Water', count: 0},
  vegetationGrowth: {name: 'Vegetation Growth', count: 0},
  obstructionStructure: {name: 'Obstruction Structure', count: 0},
  tarpaulinOnPile: {name: 'Tarpaulin On Pile', count: 0},
}

export const getPileHealthObj = (parameter: pileHealthKeyType): pileHealthObjType => {
  const updatedObj = Object.keys(initialPileHealthState).reduce((acc, curr) => {
    const currentKey = curr as pileHealthKeyType
    acc[currentKey] = false
    return acc
  }, {} as pileHealthObjType)
  updatedObj[parameter] = true
  return updatedObj
}
