/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth, userRoleObj} from '../../modules/auth'
import AnalystDashboard from '../../modules/MaterialDashboard/PileManagement/AnalystDashboard'
import {useRole} from '../../hooks/useRole'
import ClientDashboard from '../../modules/company/ClientDashboard'

const DashboardPage: FC = () => {
  const {userRole} = useAuth()
  const {isDatesmetronUser} = useRole()
  const isClientDashboardVisible = !isDatesmetronUser
  const isDatesMetronRole = userRole === userRoleObj.DatesmetronAnalyst
  if (isClientDashboardVisible) {
    return <ClientDashboard />
  }
  return <>{isDatesMetronRole && <AnalystDashboard />}</>
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
