import {useState} from 'react'

type initialLocationStockyardStateType = {
  locationId: string
  stockyardId: string
}

const initialLocationStockyardState: initialLocationStockyardStateType = {
  locationId: '',
  stockyardId: '',
}

export const useLocationStockyard = () => {
  const [locationStockyardState, setLocationStockyardState] =
    useState<initialLocationStockyardStateType>(initialLocationStockyardState)

  const changeLocationStockyard = (fields: Partial<initialLocationStockyardStateType>) => {
    setLocationStockyardState((prev) => ({...prev, ...fields}))
  }

  const onLocationChange = (
    locationId: string,
    callback: (locationId: string) => void = (locationId: string) => {}
  ) => {
    changeLocationStockyard({locationId})
    callback(locationId)
  }

  const onStockyardChange = (
    stockyardId: string,
    callback: (stockyardId: string) => void = (stockyardId: string) => {}
  ) => {
    changeLocationStockyard({stockyardId})
    callback(stockyardId)
  }
  return {
    locationId: locationStockyardState.locationId,
    stockyardId: locationStockyardState.stockyardId,
    onLocationChange,
    onStockyardChange,
    changeLocationStockyard,
  }
}
