import {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
export interface AppModalHeaderProps {
  onClose: () => void
  headerText: string
}

const AppModalHeader: FC<AppModalHeaderProps> = ({onClose, headerText}) => {
  return (
    <div className='modal-header'>
      <h2>{headerText}</h2>
      {/* begin::Close */}
      <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClose}>
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export default AppModalHeader
