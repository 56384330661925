import {useState} from 'react'
import {Range, RangeKeyDict} from 'react-date-range'
import {addDays} from 'date-fns'
export const useDateRangeState = (startDate = new Date(), endDate = addDays(new Date(), 7)) => {
  const initialState: Range = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }
  const [dateState, setDateState] = useState([{...initialState}])
  const [dateFiltered, setDateFiltered] = useState(false)
  const [dateLoading, changeDateLoading] = useState(false)
  const setDateIsFiltered = () => setDateFiltered(true)
  const removeDateFiltered = () => setDateFiltered(false)
  const handleChange = (item: RangeKeyDict) => {
    setDateState([item.selection])
  }
  const resetDateState = () => {
    setDateState([initialState])
    removeDateFiltered()
  }
  const setDateLoading = () => changeDateLoading(true)
  const reseetDateLoading = () => changeDateLoading(false)
  return {
    dateState,
    dateFiltered,
    dateLoading,
    setDateLoading,
    reseetDateLoading,
    handleChange,
    resetDateState,
    setDateIsFiltered,
    removeDateFiltered,
  }
}
