import AppLabelElementContainer from '../../../components/AppLabelElementContainer'
import AppSelect from '../../../components/AppSelect'

interface SelectElementProps {
  loading: boolean
  label: string
  value: any
  data: any[]
  placeholder: string
  width?: number
  onChange: (value: any) => void
}
const SelectElement = ({
  loading,
  onChange,
  label,
  data,
  value,
  placeholder,
  width = 45,
}: SelectElementProps) => {
  return !loading ? (
    <AppLabelElementContainer style={{width: `${width}%`}}>
      <label>{label}</label>
      <AppSelect
        placeholder={placeholder}
        value={value}
        onChange={(value: any) => onChange(value)}
        data={data}
      />
    </AppLabelElementContainer>
  ) : (
    <div style={{width: '45%'}}>Getting {label}...</div>
  )
}
export default SelectElement
