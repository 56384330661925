import React, {useState} from 'react'
import {AppSelectLocationDepartment} from '../../MaterialDashboard/AddBulkMaterial/AddBulkMaterialSelectLocationDepartment'
import {useLocationDpartment} from '../../../hooks/useLocationDepartment'
import DatePickerWithRemoveFilterBtn from '../../MaterialDashboard/Survey/DatePickerWithRemoveFilterBtn'
import {ISurveyDataAndCount, ISurveyList} from '../../MaterialDashboard/model'
import {useDatePicker} from '../../../hooks/useDatePicker'
import {getSurveyDateAndCountByDeptId} from '../../MaterialDashboard/service'
import AppDatePicker from '../../../components/AppDatePicker'
import {appRoutes} from '../../../routing/routes'
import {
  getDateInDefaultFormat,
  getdepartmentLocalStorageKey,
  getLocationLocalStorageKey,
  getSurveyDateLocalStorageKey,
} from '../../../Utils/getTokenDecoded'
import {useLocation, useNavigate} from 'react-router-dom'

const getSurveyDateLocalStorageDate = (key: string) => {
  return localStorage.getItem(key)
}

const ShowLastMonthSurveyDates = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const surveyDateLocalStorageKey = getSurveyDateLocalStorageKey(pathname)
  const dateFromLocalStorage: string | null =
    getSurveyDateLocalStorageDate(surveyDateLocalStorageKey)
  const dateFromLocalStorageInDateFormat = dateFromLocalStorage
    ? new Date(dateFromLocalStorage)
    : null
  const {
    departmentId: department,
    locationId: location,
    onDepartmentChange,
    onLocationChange,
  } = useLocationDpartment()
  const {
    surveyDataAndCount,
    changeDateLoading,
    changeSelectedDate,
    changeSurveyDateAndCount,
    dateLoading,
    getDateArray,
    selectedDate,
  } = useDatePicker(dateFromLocalStorageInDateFormat)

  const fetchSurveyDateAndCount = async (id: string) => {
    try {
      changeDateLoading(true)
      const {data} = await getSurveyDateAndCountByDeptId(id)
      changeSurveyDateAndCount(data)
      return data
    } catch (error) {
      console.error('survey date count error', error)
      return null
    } finally {
      changeDateLoading(false)
    }
  }
  const handleDepartmentChange = (departmentId: string) => {
    onDepartmentChange(departmentId)
    fetchSurveyDateAndCount(departmentId)
  }
  const navigateToSurveyListScreen = (
    dateString: string,
    locationId: string,
    departmentId: string
  ) => {
    const surveyScreenRoute = appRoutes.surveys

    const locationIdLocalStorageKey = getLocationLocalStorageKey(surveyScreenRoute)
    const departmentIdLocalStorageKey = getdepartmentLocalStorageKey(surveyScreenRoute)
    const surveyDateLocalStorageKey = getSurveyDateLocalStorageKey(surveyScreenRoute)
    localStorage.setItem(locationIdLocalStorageKey, locationId)
    localStorage.setItem(departmentIdLocalStorageKey, departmentId)
    localStorage.setItem(surveyDateLocalStorageKey, dateString)
    navigate(appRoutes.surveys)
  }
  const handleDateSelection = (date: Date) => {
    changeSelectedDate(date)
    const dateString = getDateInDefaultFormat(date)
    localStorage.setItem(surveyDateLocalStorageKey, dateString)
    navigateToSurveyListScreen(dateString, location, department)
  }
  return (
    <div className='p-4 bg-light rounded shadow-md my-5'>
      <p className='text-center h4'>Survey Dates</p>
      <div className='d-flex justfiy-content=between align-items-center' style={{gap: '1rem'}}>
        <div style={{flexGrow: 1}}>
          <AppSelectLocationDepartment
            departmentValue={department}
            locationValue={location}
            onDepartmentChange={handleDepartmentChange}
            onLocationChange={onLocationChange}
            selectElementWidth={100}
          />
        </div>
        <div>
          <AppDatePicker
            date={selectedDate}
            onChange={(date: Date) => handleDateSelection(date)}
            isSelectedDates={true}
            enabledDates={getDateArray(surveyDataAndCount)}
            placeholder={dateLoading ? 'Getting dates...' : 'Select Date'}
            isLoading={dateLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default ShowLastMonthSurveyDates
