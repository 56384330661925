/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid h-100'
      style={{backgroundColor: '#fff'}}
    >
      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1'
        style={{backgroundColor: '#FEBD16'}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}

          {/* end::Logo */}

          {/* begin::Image */}
          <img
            alt='Logo'
            src='https://dev.stockpilevolume.com/public/assets/media/logos/sv_logo.png'
            width='375'
            height='150'
            className='kt-header__brand-logo-default  login_logo'
          />
          {/* end::Image */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <h3 style={{color: '#585b5d'}}>Welcome to Stockpile Volume </h3>
          <div className='w-100 p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <>
          <div className='d-flex flex-center flex-wrap px-5'></div>
        </>
        {/* end::Footer */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
