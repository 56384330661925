import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {getJwtDataFromToken} from '../../../Utils/getTokenDecoded'
import {CompanyRoles, DatesmetronRoles, companyOrDatesmetronRole} from '../../../Utils/constants'

export type UserRole = companyOrDatesmetronRole | null

export const userRoleType = [
  {label: 'DatesMetron Role', value: 'datesMetronRole'},
  {label: 'Company Role', value: 'companyRoles'},
]

export const userRoleObj: Record<companyOrDatesmetronRole, companyOrDatesmetronRole> = {
  CompanyAdmin: 'CompanyAdmin',
  LocationHead: 'LocationHead',
  User: 'User',
  Auditor: 'Auditor',
  Superadmin: 'Superadmin',
  DatesmetronAnalyst: 'DatesmetronAnalyst',
  Supervisor: 'Supervisor',
  Surveyor: 'Surveyor',
}

export const userRoleDisplayObj = {
  [userRoleObj.CompanyAdmin]: 'Company Admin',
  [userRoleObj.LocationHead]: 'Location Head',
  [userRoleObj.User]: 'Department User',
  [userRoleObj.Auditor]: 'Auditor',
  [userRoleObj.Superadmin]: 'Super Admin',
  [userRoleObj.DatesmetronAnalyst]: 'Dates Metron Analyst',
  [userRoleObj.Supervisor]: 'Supervisor',
  [userRoleObj.Surveyor]: 'Surveyor',
} as Record<companyOrDatesmetronRole, string>

const companyAdmin = {label: 'Company Admin', value: userRoleObj.CompanyAdmin}
const departmentUser = {label: 'User', value: userRoleObj.User}
const locationHead = {label: 'Location Head', value: userRoleObj.LocationHead}
const auditor = {label: 'Auditor', value: userRoleObj.Auditor}
const superAdmin = {label: 'Super Admin', value: userRoleObj.Superadmin}
const datesMetronAnalyst = {label: 'Datesmetron Analyst', value: userRoleObj.DatesmetronAnalyst}
const supervisor = {label: 'Supervisor', value: userRoleObj.Supervisor}
const Surveyor = {label: 'Surveyor', value: userRoleObj.Surveyor}

//it gives the userRole Accessiblity
const userRoles = {
  [userRoleObj.Superadmin]: [companyAdmin, datesMetronAnalyst, supervisor, Surveyor],
  [userRoleObj.CompanyAdmin]: [auditor, locationHead, departmentUser],
  [userRoleObj.LocationHead]: [departmentUser],
  [userRoleObj.Auditor]: [],
  [userRoleObj.DatesmetronAnalyst]: [],
  [userRoleObj.User]: [],
}

export const getUserRolesList = (userRole: string) => {
  return userRoles[userRole]
}
export const getUserRoleType = (userRole: string) => {
  return userRole === userRoleObj.Superadmin
    ? [
        {label: 'DatesMetron Role', value: 'datesMetronRole'},
        {label: 'Company Roles', value: 'companyRole'},
      ]
    : [{label: 'Company Roles', value: 'companyRole'}]
}

export const getIsDatesMetronUser = (userRole: string) => {
  const {DatesmetronAnalyst, Superadmin, Supervisor, Surveyor} = userRoleObj
  return [Superadmin, DatesmetronAnalyst, Supervisor, Surveyor].some(
    (datesMetronUserRole) => userRole === datesMetronUserRole
  )
}

export const getIsDatesMetronUserSelected = (userRole: string) => {
  const {DatesmetronAnalyst, Supervisor, Surveyor} = userRoleObj
  return [DatesmetronAnalyst, Supervisor, Surveyor].some(
    (datesMetronUserRole) => userRole === datesMetronUserRole
  )
}

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: string | undefined
  setCurrentUser: Dispatch<SetStateAction<string | undefined>>
  logout: () => void
  userRole: CompanyRoles | DatesmetronRoles | null
  changeUserRole: (role: UserRole) => void
  userId: string
  changeUserId: (userId: string) => void
  locationId: string
  changeLocationId: (locationId: string) => void
  departmentId: string
  changeDepartmentId: (locationId: string) => void
  userEmail: string
  changeUserEmail: (userEmail: string) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  userRole: null,
  changeUserRole: (role: UserRole) => {},
  userId: '',
  changeUserId: (userId: string) => {},
  locationId: '',
  changeLocationId: (locationId: string) => {},
  departmentId: '',
  changeDepartmentId: (locationId: string) => {},
  userEmail: '',
  changeUserEmail: (userEmail: string) => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<string | undefined>()
  const [userRole, setUserRole] = useState<UserRole>(null)
  const [userId, setUserId] = useState('')
  const changeUserRole = (userRole: UserRole) => setUserRole(userRole)
  const changeUserId = (userId: string) => setUserId(userId)
  const [locationId, setLocationId] = useState('')
  const changeLocationId = (locationId: string) => setLocationId(locationId)
  const [departmentId, setDepartmentId] = useState('')
  const changeDepartmentId = (departmentId: string) => setDepartmentId(departmentId)
  const [userEmail, setUserEmail] = useState('')
  const changeUserEmail = (userEmail: string) => setUserEmail(userEmail)
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    changeUserRole(null)
    setUserId('')
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        userRole,
        changeUserRole,
        userId,
        changeUserId,
        locationId,
        departmentId,
        changeDepartmentId,
        changeLocationId,
        userEmail,
        changeUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {
    auth,
    logout,
    setCurrentUser,
    changeUserRole,
    changeUserId,
    changeDepartmentId,
    changeLocationId,
    changeUserEmail,
  } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  const removeUser = () => {
    setCurrentUser(undefined)
    changeUserRole(null)
    changeUserId('')
    changeLocationId('')
    changeDepartmentId('')
    changeUserEmail('')
  }
  useEffect(() => {
    setTimeout(() => {
      const tokenData = authHelper.getAuth()
      const token = tokenData?.token
      if (token) {
        setCurrentUser(token as unknown as string)
        const jwtData = getJwtDataFromToken(token as unknown as string)
        changeUserRole(jwtData?.role)
        changeUserId(jwtData?.userId)
        changeLocationId(jwtData?.locationId || '')
        changeDepartmentId(jwtData?.departmentId || '')
        changeUserEmail(jwtData?.email || '')
      } else {
        removeUser()
      }
      setShowSplashScreen(false)
    }, 3500)
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
